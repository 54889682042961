import React, {useState, useEffect} from 'react';

import BaseLayout from './components/BaseLayout';
import DataTable from "./components/DataTable";

import Card from "@mui/material/Card";
import { Box, Grid, Paper, Typography, Select, MenuItem, Button, TextField } from '@mui/material';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

import MDButton from 'components/MDButton';

import axios from 'axios';

import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import YtVideos from 'components/YtVideos';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { Edit, Delete, CloudUpload } from '@mui/icons-material';
import { IconButton, Tooltip, Modal } from '@mui/material';
import axiosInstance from 'api/axios';

import {toast} from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
// import DataTableWithModal from 'components/DataTables/DataTableWithModal';

import MDInput from 'components/MDInput';

const Import = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const group_id = localStorage.getItem('group_id');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tableData, setTableData] = useState(null); // Data for DataTable
    const [ytData, setYtData] = useState([]); // Data for DataTable
    const [tags, setTags] = useState([]); // Tags options
    const [selectedTag, setSelectedTag] = useState(''); // Selected tag
    const [selectedDate, setSelectedDate] = useState(null); // Selected date
    const [openModal, setOpenModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [newSubscriber, setNewSubscriber] = useState({
      name: "",
      email: "",
      mobile: "",
      company: "",
      tag_id: [],
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setSelectedFile(file);
        } else {
            toast.error('Please select a valid XLSX file');
            event.target.value = null;
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select a file to upload');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('group_id', group_id);

        try {
            const response = await axiosInstance.post('/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('File uploaded successfully');
            setUploadModal(false);
            setSelectedFile(null);
            // Refresh data after upload
            fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit});
        } catch (error) {
            console.error('Upload failed:', error);
            toast.error('Upload failed. Please try again.');
        }
    };

    const CustomToolbar = () => (
        <MDBox display="flex" justifyContent="space-between" className={'top-bar-flex gap-2'} alignItems="center" p={3}>
            
            <MDButton color="dark" sx={{background: '#811fd1'}} onClick={() => setUploadModal(true)}>
                <CloudUpload /> &nbsp;
                Upload File
            </MDButton>
            <MDButton color="dark" sx={{background: '#811fd1'}} >
                <CloudUpload /> &nbsp;
                Delete All Pre-Imported Data
            </MDButton>
            <MDButton color="dark" sx={{background: '#e33270'}} >
                <CloudUpload /> &nbsp;
                Import Now
            </MDButton>
        </MDBox>
    );

    // Upload Modal
    const uploadModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    useEffect(() => {
        fetchYtData({page:'your_public'});
    }, []);
    
    useEffect(() => {
        // fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit });
    }, [offset, selectedTag, selectedDate])

    const handleEdit = (id) => {
        console.log("Edit clicked for:", id);
        navigate(`/subscribers/EditSubscriber/${id}`);
    };
      
    // Function to handle delete
    const handleDelete = (id, name) => {
        setSelectedUser({id, name});
        setDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.post(`/api_delete_user_group`, {user_id:selectedUser.id, group_id:group_id});
            toast.success('Subscriber deleted successfully');
            fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit});
            setDeleteModal(false);
        } catch (error) {
            console.error('Error deleting subscriber:', error);
            toast.error('Error deleting subscriber');
        }
    };

    const fetchData = async (params = {}) => {
        try {
            const response = await axiosInstance.get(`/api_get_user_temp?group_id=${group_id}`); // Adjust API endpoint
            setTags(response.data.tags); 
            setTotalEntries(response.data.total);
            const rows = response.data.data.map((row,i)=>{
                
                return {
                    id: row.id,
                    name: row.name,
                    mobile: row.mobile,
                    email: row.email, 
                    dob: row.dob,
                    tagid: row.tagid,
                    company: row.company,
                    address: row.address,
                    city: row.city,
                    state: row.state,
                    zipcode: row.zipcode,
                    cpf: row.cpf,
                    datepurchase: row.datepurchase,
                    dateexpiring: row.dateexpiring,
                    responsable: row.responsable,
                    product: row.product,
                    value: row.value,
                    extra1: row.extra1,
                    extra2: row.extra2,
                    actions: (
                        <>
                          <Tooltip title="View">
                            <IconButton sx={{ color: '#f72585' }} >
                              <ArrowForward />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row.user_id)} sx={{ color: '#f72585' }} >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(row.user_id, row.name)} sx={{ color: '#f72585' }}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </>
                    ),
                }
            })
            const data = {
                columns: [
                    { Header: "ID", accessor: "id", width: "20%" },
                    { Header: "Name", accessor: "name", width: "25%" },
                    { Header: "Mobile", accessor: "mobile" },
                    { Header: "Email", accessor: "email", width: "7%" },
                    { Header: "DOB", accessor: "dob" },
                    { Header: "Tag ID", accessor: "tagid" },
                    { Header: "Company", accessor: "company" },
                    { Header: "Address", accessor: "address" },
                    { Header: "City", accessor: "city" },
                    { Header: "State", accessor: "state" },
                    { Header: "ZIP Code", accessor: "zipcode" },
                    { Header: "CPF", accessor: "cpf" },
                    { Header: "Date Purchase", accessor: "datepurchase" },
                    { Header: "Date Expiring", accessor: "dateexpiring" },
                    { Header: "Responsable", accessor: "responsable" },
                    { Header: "Product", accessor: "product" },
                    { Header: "Value", accessor: "value" },
                    { Header: "Extra 1", accessor: "extra1" },
                    { Header: "Extra 2", accessor: "extra2" },
                    { Header: "Actions", accessor: "actions", disableSorting: true }
                ],
                rows: rows
            }
            setTableData(data);
            console.log('data', data);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (page) => {
        console.log(page, 'page');
        
    };
    const handlePagePrev = () => {
        if(page > 0){
            setPage(page - 1);
            setOffset(page * limit);
        }
    };
    const handlePageNext = () => {
        console.log('next', Math.ceil(totalEntries / limit), page);
        
        if(page < totalEntries / limit ){
            setPage(page + 1);
            setOffset(page * limit);
        }
    };

    return (
        <BaseLayout stickyNavbar={true}>
            <Card>
                <MDBox p={3} lineHeight={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <MDTypography variant="h5" fontWeight="medium">
                         
                    </MDTypography>
                    <CustomToolbar />
                </MDBox>
                {tableData &&
                    <DataTable 
                        table={tableData} 
                        canSearch 
                        handlePageChange={handlePageChange} 
                        handlePageNext={handlePageNext} 
                        handlePagePrev={handlePagePrev} 
                        paage={page} 
                        setPage={setPage} 
                    />
                }
            </Card>

            {/* Upload Modal */}
            <Modal
                open={uploadModal}
                onClose={() => {
                    setUploadModal(false);
                    setSelectedFile(null);
                }}
            >
                <>
                    <Box sx={uploadModalStyle}>
                        <MDTypography variant="h6" component="h2" mb={3}>
                        Click here to download xls sample for import
                        </MDTypography>
                        {/* <MDTypography variant="h6" component="h2" mb={3}>
                            Upload XLSX File
                            </MDTypography> */}
                        <MDInput
                            type="file"
                            accept=".xlsx"
                            onChange={handleFileChange}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                        />
                        <MDTypography variant="h6" component="h2" mb={0}>
                        Colllumn names that can be imported:
                        </MDTypography>
                        <MDTypography variant="body2" component="h2" mb={3}>
                        name, mobile, email, birthday, tagid, company, address, number, complement, district, city, state, zip code, purchasedate, duedate, operator, product, value, extra1, extra2.
                        </MDTypography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <MDButton 
                                onClick={() => {
                                    setUploadModal(false);
                                    setSelectedFile(null);
                                }}
                                color="secondary"
                            >
                                Cancel
                            </MDButton>
                            <MDButton 
                                onClick={handleUpload}
                                color="info"
                                sx={{background: '#3e36c9'}}
                            >
                                Import
                            </MDButton>
                        </Box>
                    </Box>
                </>
            </Modal>
        </BaseLayout>
    );
};

export default Import;
