import React, {useState, useEffect} from 'react';

import BaseLayout from './components/BaseLayout';
import DataTable from "./components/DataTable";

import Card from "@mui/material/Card";
import { Box, Grid, Paper, Typography, Select, MenuItem, Button, TextField } from '@mui/material';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

import MDButton from 'components/MDButton';

import axios from 'axios';

import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import YtVideos from 'components/YtVideos';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Edit, Delete } from '@mui/icons-material';
import { IconButton, Tooltip, Modal } from '@mui/material';
import axiosInstance from 'api/axios';

import {toast} from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
// import DataTableWithModal from 'components/DataTables/DataTableWithModal';

import MDInput from 'components/MDInput';



const YourPublic = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const group_id = localStorage.getItem('group_id');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tableData, setTableData] = useState(null); // Data for DataTable
    const [ytData, setYtData] = useState([]); // Data for DataTable
    const [tags, setTags] = useState([]); // Tags options
    const [selectedTag, setSelectedTag] = useState(''); // Selected tag
    const [selectedDate, setSelectedDate] = useState(null); // Selected date
    const [openModal, setOpenModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [newSubscriber, setNewSubscriber] = useState({
      name: "",
      email: "",
      mobile: "",
      company: "",
      tag_id: [],
    });

    const handleExport = async () => {
        try {
            const response = await axiosInstance.get(`/export/${group_id}`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'subscribers.csv'); 
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            toast.success('Export Successfully!');
        } catch (error) {
            console.error('Export failed:', error);
            toast.error('Export failed. Please try again.');
        }
    }

    const CustomToolbar = () => (
        <MDBox display="flex" justifyContent="space-between" className={'top-bar-flex gap-2'} alignItems="center" p={3}>
          <MDButton onClick={handleExport} color="dark" sx={{background: '#60d3ca'}} ><ImportExportIcon />Export</MDButton>
          <MDButton component={Link} to="/import" color="dark" sx={{background: '#3f37c9'}} ><ImportExportIcon />Import</MDButton>
            <MDButton component={Link} to="/tags" color="dark" sx={{background: '#f72585'}} >Tags</MDButton>
            <MDButton color="dark" sx={{background: '#480ca8'}} onClick={() => setOpenModal(true)}><AddIcon />
            Add Subscriber
            </MDButton>
        </MDBox>
      );

    useEffect(() => {
        fetchYtData({page:'your_public'});
    }, []);
    
    useEffect(() => {
        fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit });
    }, [offset, selectedTag, selectedDate])

    const handleAddSubscriber = async () => {
        try {
            const formData = {
                ...newSubscriber,
                groupid: localStorage.getItem('group_id')
            };
            
            const response = await axios.post('https://321dbase.com/api/user_save_profile.php', formData);
            
            // if(response.data.success) {
                setOpenModal(false);
                setNewSubscriber({
                    name: "",
                    email: "",
                    mobile: "",
                    company: "",
                    tag_id: []
                });
                // Refresh table data
                fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit });
                toast.success('Subscriber added successfully');
            // }
        } catch (error) {
            console.error('Error adding subscriber:', error);
        }
    };

    const handleEdit = (id) => {
        console.log("Edit clicked for:", id);
        navigate(`/subscribers/EditSubscriber/${id}`);
    };
      
    // Function to handle delete
    const handleDelete = (id, name) => {
        setSelectedUser({id, name});
        setDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.post(`/api_delete_user_group`, {user_id:selectedUser.id, group_id:group_id});
            toast.success('Subscriber deleted successfully');
            fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit});
            setDeleteModal(false);
        } catch (error) {
            console.error('Error deleting subscriber:', error);
            toast.error('Error deleting subscriber');
        }
    };

    const fetchData = async (params = {}) => {
    try {
        const response = await axiosInstance.get('/api_users_by_group', { params }); // Adjust API endpoint
        setTags(response.data.tags); 
        setTotalEntries(response.data.total);
        const rows = response.data.data.map((row,i)=>{
            let dob = ``;
            if(row?.dob_day){
                dob = `${row?.dob_day}/${row?.dob_month}/${row?.dob_year}`
            }
            return (
                {
                    user_id : row.user_id,
                    name : row.name,
                    company : row.company,
                    mobile : row.mobile,
                    dob : dob,
                    created_at : row.created_at,
                    updated_at : row.updated_at,
                    actions: (
                        <>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row.user_id)} sx={{ color: '#f72585' }} >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(row.user_id, row.name)} sx={{ color: '#f72585' }}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </>
                      ),
                }
            )
        })
        const data = {
            columns: [
                { Header: "ID", accessor: "user_id", width: "20%" },
                { Header: "Name", accessor: "name", width: "25%" },
                { Header: "Company", accessor: "company" },
                { Header: "Mobile", accessor: "mobile", width: "7%" },
                { Header: "DOB", accessor: "dob" },
                { Header: "Created", accessor: "created_at" },
                { Header: "Updated", accessor: "updated_at" },
                { Header: "actions", accessor: "actions", disableSorting: true }
            ],
            rows: rows
        }
        setTableData(data);
        console.log('data', data);
        
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    };
    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (page) => {
        console.log(page, 'page');
        
    };
    const handlePagePrev = () => {
        if(page > 0){
            setPage(page - 1);
            setOffset(page * limit);
        }
    };
    const handlePageNext = () => {
        console.log('next', Math.ceil(totalEntries / limit), page);
        
        if(page < totalEntries / limit ){
            setPage(page + 1);
            setOffset(page * limit);
        }
    };
    


      // Handle Tag Change
    const handleTagChange = (event) => {
        const selectedTag = event.target.value;
        setSelectedTag(selectedTag);
        // fetchData({ tag: selectedTag, date: selectedDate });
    };

    // Handle Date Change
    const handleDateChange = (newDate) => {

        const formatDate = (date) => {
            const year = String(date.getFullYear()); // Extract last 2 digits of year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(date.getDate()).padStart(2, '0'); // Pad single digits with 0
            return `${year}-${month}-${day}`;
        };
        const date = formatDate(new Date(newDate))
        setSelectedDate(date);
        
        // fetchData({ tag: selectedTag, dob: date });
    };

  return (
    <BaseLayout stickyNavbar={true}>
        {
            ytData &&
            <Box className={'mb-4'}> 
                <YtVideos data={ytData} />
            </Box>
        }
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, p: 2 }} className={'top-bar-flex'}>
            <div>
                <label htmlFor="" className="label-text">
                    Select Tags
                </label>
                <Select defaultValue="" displayEmpty onChange={handleTagChange} fullWidth sx={{ padding: '12px 5px;' }}>
                    <MenuItem value="">Select Option</MenuItem>
                    {tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.tag}>
                            {tag.tag}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <label htmlFor="" className="label-text">Filter By Birthday</label>
                <div className="datepicker">
                    <MDDatePicker placeholder="Select Date" value={selectedDate} onChange={handleDateChange} />
                    <CalendarTodayIcon />
                </div>
            </div>
            <MDButton color="dark" sx={{background: '#4cc9f0'}} >Make Custom Public</MDButton>
            <Button variant="outlined" color="primary" style={{color: '#000'}}>
                Card View
            </Button>
        </Box>
        <Card>
            <MDBox p={3} lineHeight={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <MDTypography variant="h5" fontWeight="medium">
                    Subscribers Table
                </MDTypography>
                <CustomToolbar />
            </MDBox>
            {
                tableData &&
                <DataTable table={tableData} canSearch handlePageChange={handlePageChange} handlePageNext={handlePageNext} handlePagePrev={handlePagePrev} paage={page} setPage={setPage} />
            }
        </Card>

        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="add-subscriber-modal"
        >
            <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 2,
                boxShadow: 24,
            }}
            >
            <Typography id="add-subscriber-modal" variant="h6">
                Add Subscriber
            </Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <MDInput
                        fullWidth
                        label="Name"
                        value={newSubscriber.name}
                        onChange={(e) => setNewSubscriber({ ...newSubscriber, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDInput
                        fullWidth
                        label="Email" 
                        value={newSubscriber.email}
                        onChange={(e) => setNewSubscriber({ ...newSubscriber, email: e.target.value })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MDInput
                        fullWidth
                        label="Mobile"
                        value={newSubscriber.mobile}
                        onChange={(e) => setNewSubscriber({ ...newSubscriber, mobile: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDInput
                        fullWidth
                        label="Company"
                        value={newSubscriber.company}
                        onChange={(e) => setNewSubscriber({ ...newSubscriber, company: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Select
                        fullWidth
                        multiple
                        label="Tag"
                        value={newSubscriber.tag_id}
                        onChange={(e) => setNewSubscriber({ ...newSubscriber, tag_id: e.target.value })}
                        margin="normal"
                        sx={{ padding: '12px 5px;', width: '100%' }}
                    >
                        <MenuItem value="">Select Tag</MenuItem>
                        {tags.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>
                                {tag.tag}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            
            
            
                <MDBox display="flex" gap={2} className={'mt-4'}>
                  <MDButton variant="contained" color="secondary" fullWidth onClick={() => setOpenModal(false)}>
                    Cancel
                  </MDButton>
                  <MDButton variant="contained" color="primary" fullWidth onClick={handleAddSubscriber}>
                    Add
                  </MDButton>
                </MDBox>
            </Box>
        </Modal>

        <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="delete-subscriber-modal"
        >
            <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 2,
                boxShadow: 24,
            }}
            >
            <Typography id="delete-subscriber-modal" variant="h6">
                Delete Subscriber
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, fontSize: '16px' }}>
                Are you sure you want to delete {selectedUser?.name}?
            </Typography>
            <MDBox display="flex" gap={2} className={'mt-4'}>
                <MDButton variant="contained" color="secondary" fullWidth onClick={() => setDeleteModal(false)}>
                Cancel
                </MDButton>
                <MDButton variant="contained" color="error" fullWidth onClick={confirmDelete}>
                Delete
                </MDButton>
            </MDBox>
            </Box>
        </Modal>
    
    </BaseLayout>
  );
};

export default YourPublic;
