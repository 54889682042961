import React, { useState, useContext  } from 'react';
import { toast } from 'react-toastify';
import logo from 'assets/images/logo.jpg'
import {Grid} from '@mui/material';
import lp from '../../assets/images/lp.jpg'
import axiosInstance from '../../api/axios';
import { useNavigate } from 'react-router-dom';

import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../assets/css/main.css'

import { AuthContext } from '../../context/AuthContext';

import axios from 'axios';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // return re.test(String(email).toLowerCase());
    return email != '';
  };

  const validatePassword = (password) => {
    // return password.length >= 6;
    return password != '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateEmail(email)) {
      toast.error('Invalid username');
      return;
    }
    if (!validatePassword(password)) {
      toast.error('Password must be at least 6 characters long');
      return;
    }

    const formData = new FormData();
    formData.append('login', email);
    formData.append('password', password);

    try {
      const response = await axiosInstance.post('/api_login', formData);
      const { data, message, success } = response.data;

      if(!success) {
        toast.success(message);
        return;
      }

      // Store token and update AuthContext
      if(data) {
        localStorage.setItem('authToken', data.user.token);
        localStorage.setItem('group_id', data.user.id);
        localStorage.setItem('group_name', data.user.gp_groupname);
        setIsAuthenticated(true);
      }

      toast.success(message);
      navigate('/dashboard');
    } catch (error) {
      // localStorage.setItem('authToken', 'logintoken');
      // setIsAuthenticated(true);
      // toast.success('Logged in successfully');
      toast.error(error.response?.data?.message || 'Login failed');
    }
  };

  return (
    <>
    <nav className='navbar'>
    <div className='container-fluid'>
      <div className='navbar-logo'>
        <img src={logo} alt='Logo' />
        </div>
        <div className='navbar-login'>
        <form onSubmit={handleSubmit}>
        <div className="head-form">
        <div className="form-group">
        <div className="inp-grp">
        <input
        type='text'
        placeholder='Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        
        />
        </div>
        </div>
        <div className="form-group">
        <div className="inp-grp">
        <input
        type='password'
        placeholder='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        
        />
        </div>
        </div>
        <button type='submit'><span>Submit</span></button>
        </div>
        </form>
        </div>
        </div>
        </nav>
        <img src={lp} className='lp-img' alt='Logo' />
        
    </>
        );
      }

      
export default LandingPage;