import React, { useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    Radio,
    RadioGroup,
    FormControlLabel,
  } from "@mui/material";
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import youtubeIcon from 'assets/images/youtubeicons/youtube.jpg';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from 'api/axios';
import { useEffect } from 'react';
import YtVideos from 'components/YtVideos';
import {toast} from 'react-toastify';
import { useFormik } from 'formik';



const Generate_Campaigns = () => {
    const [openDialog, setOpenDialog] = useState(null);
    const [age, setAge] = useState("");
    const [switchChecked, setSwitchChecked] = useState(false);
    const [areaCode, setAreaCode] = React.useState("all");
    const [areaCodeInput, setAreaCodeInput] = React.useState("");
    const [ytData, setYtData] = useState([]);
    const [messageReports, setMessageReports] = useState([]);
    const [dailyLimit, setDailyLimit] = useState(0);
    const [messages, setMessages] = useState([]);
    const [tags, setTags] = useState([
        {
            id: 1,
            name: 'Todas Etiquetas'
        },
        {
            id: 2,
            name: 'd'
        },
        {
            id: 3,
            name: 'testddd'
        },
        {
            id: 4,
            name: 'is_cliente'
        },
        {
            id: 5,
            name: 'agencies'
        },
    ]);
    const [otherSelect, setOtherSelect] = useState([]);
    
    const handleCardClick = (title) => {
       
        setOpenDialog("readyToSend");
       
    };
    const handleCardDailyClick = (title) => {
        
     
        setOpenDialog("dailyLimit");
        
    };

    const group_id = localStorage.getItem('group_id');

    const fetchData = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_compaign_sleSmsReport', { group_id: group_id  });
            setMessageReports(response.data); 
            setSwitchChecked(response.data.is_webcampaigns)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchMessages = async () => {
        try {
            const response = await axiosInstance.post('/api_compaign_template', { group_id: group_id });
            setMessages(response.data.data);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchTags = async () => {
        try {
            const response = await axiosInstance.get('/api_get_tags');
            // setTags(response.data);
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    const fetchOtherSelect = async () => {
        try {
            const response = await axiosInstance.get('/api_get_columns?group_id=' + group_id);
            setOtherSelect(response.data.data);
        } catch (error) {
            console.error('Error fetching other select:', error);
        }
    };

    const updateDailyLimit = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_updateDailyLimit', { group_id: group_id, daily_limit: dailyLimit });
            fetchData();
            toast.success('Daily limit updated successfully');
            setOpenDialog(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error updating daily limit');
        }
    };

    const readyToSend = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_updateReadySent_50', { group_id: group_id });
            fetchData();
            toast.success(`Updated Records ${response.data.updated_records}`);
            setOpenDialog(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error updating ready to send');
        }
    };

    useEffect(() => {
        fetchYtData({page:'ai_settings'});
        fetchData();
        fetchMessages();
        fetchTags();
        fetchOtherSelect();
    }, []);
  
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    
    // Function to close the dialog
    const handleClose = () => {
        setOpenDialog(null);
    };
    // Handle switch toggle
    const handleSwitchChange = (event) => {
        setSwitchChecked(event.target.checked);

        axiosInstance.post('/api_status_webcampaign', { group_id: group_id, is_webcampaigns: event.target.checked })
        .then(response => {
            console.log('Web campaigns updated successfully:', response.data);
            toast.success('Web campaigns updated successfully');
        })
        .catch(error => {
            console.error('Error updating web campaigns:', error);
            toast.error('Error updating web campaigns');
        });
    };

    const formik = useFormik({
        initialValues: {
            message: '',
            tags: [],
            otherSelect: ''
        },
        onSubmit: values => {
            console.log(values);
            // Handle form submission
            const data = {
                group_id: group_id,
                campaign_id: values.message,
                total_tags: values.tags,
                column_id: values.otherSelect,
                area_code: areaCodeInput,
            }
            axiosInstance.post('/api_generate_campaign', data)
            .then(response => {
                console.log('Campaign generated successfully:', response.data);
                toast.success('Campaign generated successfully');
            })
            .catch(error => {
                console.error('Error generating campaign:', error);
                toast.error('Error generating campaign');
            });
        }
    });

    return (
        <Box sx={{ padding: 3, marginLeft: '15rem', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            {/* Render the Navbar */}
            <DashboardNavbar />

            {/* Grid for Cards */}
            <Grid container spacing={0} sx={{marginTop:'5rem'}}>
                <Grid item xs={12}>
                    {
                        ytData &&
                        <Box className={'mb-4'}> 
                            <YtVideos data={ytData} />
                        </Box>
                    }
                    

                    <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 3 }}>
                        {/* Iterate through cards */}
                            <Card
                              
                                onClick={() => handleCardClick()}
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {messageReports?.sent_count?.ready}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                Ready to send
                                </Typography>
                            </Card>
                            <Card
                              
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {messageReports?.sent_count?.queue}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                In queue for next days  
                                </Typography>
                            </Card>
                            <Card
                              
                                onClick={() => handleCardDailyClick()}
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {messageReports?.sent_count?.daily_limit}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                Daily Limit
                                </Typography>
                            </Card>
                     

                        {/* Web Delivery Switch */}
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={12}>
                                <Switch
                                checked={switchChecked}
                                onChange={handleSwitchChange}
                                color="primary"
                                />
                                <Typography variant="h5">
                                {switchChecked ? "Web Delivery On" : "Web Delivery Off"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <Typography variant="body1" sx={{ fontSize: 11 }}>
                                Turn it on to send the messages using the Web Automation system.
                                For more than 100 deliveries per day, use our Windows App.
                                </Typography>
                            </Grid>
                            </Grid>
                        </Box>

                        {/* Dialog for "Ready to Send" */}
                        <Dialog
                            open={openDialog === "readyToSend"}
                            onClose={handleClose}
                            maxWidth="xs"
                            fullWidth
                        >
                            {/* Close Icon */}
                            <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                            </IconButton>

                            {/* Dialog Content */}
                            <DialogContent>
                            <Typography
                                variant="h6"
                                sx={{
                                textAlign: "center",
                                marginBottom: 2,
                                fontWeight: "bold",
                                color: "#344767",
                                fontSize: "1.5rem",
                                }}
                            >
                                Ready to send
                            </Typography>
                            <DialogActions
                                sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                justifyContent: "center",
                                paddingBottom: 3,
                                }}
                            >
                                <Button
                                variant="contained"
                                sx={{
                                    background:
                                    "linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218))",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    padding: "10px 20px",
                                }}
                                onClick={() => readyToSend()}
                                >
                                GENERATE 30+ MESSAGE TO SEND NOW
                                </Button>
                            </DialogActions>
                            </DialogContent>
                        </Dialog>
                        {/* Dialog for "Daily Limit" */}
                        <Dialog
                            open={openDialog === "dailyLimit"}
                            onClose={handleClose}
                            maxWidth="xs"
                            fullWidth
                            sx={{
                                "& .MuiPaper-root": {
                                bottom: "30%",
                                },
                            }}
                        >
                            {/* Close Icon */}
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                position: "absolute",
                                right: 8,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            {/* Dialog Content */}
                            <DialogContent>
                                <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    marginBottom: 2,
                                    fontWeight: "bold",
                                    color: "#344767",
                                    fontSize: "1.5rem",
                                }}
                                >
                                Daily Limit
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        gap: 2,
                                        width: "100%",
                                    }}
                                    >
                                    <TextField
                                        id="outlined-number"
                                        label="Enter Limit"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: undefined, 
                                        }}
                                        value={dailyLimit}
                                        onChange={(e) => setDailyLimit(e.target.value)}
                                        sx={{
                                            marginBottom: "8px",
                                            "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#6c43e0",
                                            },
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#6c43e0",
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                        width: "80px",
                                        backgroundColor: "#6c43e0",
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        borderRadius: "8px",
                                        textTransform: "none",
                                        "&:hover": { backgroundColor: "#5a38b8" },
                                        }}
                                        onClick={() => updateDailyLimit()}
                                    >
                                        SAVE
                                    </Button>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </Box>
                    <Box sx={{ padding: "16px", backgroundColor: "#fff", boxShadow: 3, borderRadius: 2, marginTop:5 }}>
                        {/* <Typography variant="h6" sx={{ marginBottom: 3, fontWeight: "bold" }}>
                            Compose Message
                        </Typography> */}

                        {/* Grid Layout */}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={3}>
                                {/* Name the Message */}
                                {/* <Grid item xs={12}>
                                    <TextField 
                                        label="Name the Message" 
                                        variant="outlined" 
                                        fullWidth 
                                        name="message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                    />
                                </Grid> */}

                                {/* Campaign Details */}
                                <Grid item xs={12}>
                                    <Box sx={{ width: "100%" }}>
                                        <Typography variant="h6" fontWeight="bold" mb={2}>
                                            Generate Campaign
                                        </Typography>

                                        {/* Select Message */}
                                        <FormControl fullWidth margin="normal" sx={{marginBottom:3}}>
                                            <InputLabel>Select Message</InputLabel>
                                            <Select
                                                name="message"
                                                value={formik.values.message}
                                                onChange={formik.handleChange}
                                                sx={{ padding: '12px 5px;', width: '100%' }}
                                            >
                                                {messages && messages.map((message) => (
                                                    <MenuItem key={message.id} value={message.id}>
                                                        {message.campaign_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {/* Tags and Kanban Status */}
                                        <Grid container spacing={2} mb={2}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Tags</InputLabel>
                                                    <Select
                                                        name="tags"
                                                        multiple
                                                        value={formik.values.tags}
                                                        onChange={formik.handleChange}
                                                        sx={{ padding: '12px 5px;', width: '100%' }}
                                                    >
                                                        {tags && tags.map((tag) => (
                                                            <MenuItem key={tag.id} value={tag.id}>
                                                                {tag.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6}>
                                                {/* Other Select */}
                                                <FormControl fullWidth>
                                                    <InputLabel>Other Select</InputLabel>
                                                    <Select
                                                        name="otherSelect"
                                                        value={formik.values.otherSelect}
                                                        onChange={formik.handleChange}
                                                        sx={{ padding: '12px 5px;', width: '100%' }}
                                                    >
                                                        {otherSelect && otherSelect.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.column}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        {/* Radio Buttons for Area Code */}
                                        <Typography fontWeight="bold" mb={1}>
                                            Area Code
                                        </Typography>
                                        <RadioGroup
                                            row
                                            value={areaCode}
                                            onChange={(e) => setAreaCode(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="all"
                                                control={<Radio />}
                                                label="All area code"
                                            />
                                            <FormControlLabel
                                                value="select"
                                                control={<Radio />}
                                                label="Select area code"
                                            />
                                        </RadioGroup>

                                        {/* Conditional Input for Area Codes */}
                                        {areaCode === "select" && (
                                            <Box mt={2}>
                                                <Typography fontWeight="bold" color="text.primary" mb={1}>
                                                    Enter the area codes in the format <b>5511,5521,5531...</b>{" "}
                                                    (with commas between them)
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Enter area codes..."
                                                    value={areaCodeInput}
                                                    onChange={(e) => setAreaCodeInput(e.target.value)}
                                                />
                                            </Box>
                                        )}

                                        {/* Button */}
                                        <Box textAlign="right" mt={2}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#5A50E6",
                                                    color: "#FFF",
                                                    fontWeight: "bold",
                                                    "&:hover": {
                                                        backgroundColor: "#4D47D1",
                                                    },
                                                }}
                                                type="submit"
                                            >
                                                Generate Campaign Now
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
};

export default Generate_Campaigns;