import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Card, Select, MenuItem, FormControl, InputLabel, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

import axiosInstance from 'api/axios';
import BaseLayout from 'layouts/messageBots/components/BaseLayout';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const EditSubscriber = () => {
    const group_id = localStorage.getItem('group_id');
  const { id } = useParams();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responsables, setResponsables] = useState([]);

  const validationSchema = Yup.object({
    fullname: Yup.string().required('Full name is required'),
    // firstname: Yup.string().required('First name is required'),
    // birthday: Yup.date().nullable().required('Birthday is required'),
    // mobile: Yup.string().required('Mobile is required'),
    // email: Yup.string().email('Invalid email format').required('Email is required'),
    // company: Yup.string().required('Company is required'),
    // address: Yup.string().required('Address is required'),
    // number: Yup.string().required('Number is required'),
    // complement: Yup.string(),
    // district: Yup.string().required('District is required'),
    // city: Yup.string().required('City is required'),
    // state: Yup.string().required('State is required'),
    // zip: Yup.string().required('ZIP code is required'),
    // legal_id: Yup.string().required('Legal ID is required'),
    // gender: Yup.string().required('Gender is required'),
    // responsable: Yup.string().required('Responsable is required'),
    // product: Yup.string().required('Product is required'),
    // value: Yup.number().required('Value is required'),
    // extra1: Yup.string(),
    // extra2: Yup.string(),
    // last_purchase_date: Yup.date().nullable(),
    // expiring_date: Yup.date().nullable(),
    // notes: Yup.string(),
    // tags: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      firstname: '',
      birthday: null,
      mobile: '',
      email: '',
      company: '',
      address: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      zip: '',
      legal_id: '',
      gender: '',
      responsable: '',
      product: '',
      value: '',
      extra1: '',
      extra2: '',
      last_purchase_date: null,
      expiring_date: null,
      notes: '',
      tags: []
    },
    validationSchema,
    onSubmit: async (values) => {
        const data = {user_id: id, group_id: localStorage.getItem('group_id') , ...values, tags: values.tags.join(',')};
      try {
        await axiosInstance.post(`/api_update_user`, data);
        toast.success('Subscriber updated successfully');
        // navigate('/subscribers');
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error updating subscriber');
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axiosInstance.post(`/api_get_user_tags`, { group_id: group_id, user_id: id });
        const subscriber = data.data.user;
        setTags(data.data.tags);

        formik.setValues({
          fullname: subscriber.name || '',
          firstname: subscriber.fname || '',
          birthday: subscriber.dob || null,
          mobile: subscriber.mobile || '',
          email: subscriber.email || '',
          company: subscriber.company || '',
          address: subscriber.address || '',
          number: subscriber.number || '',
          complement: subscriber.complement || '',
          district: subscriber.district || '',
          city: subscriber.city || '',
          state: subscriber.state || '',
          zip: subscriber.zip || '',
          legal_id: subscriber.legal_id || '',
          gender: subscriber.gender || '',
          responsable: subscriber.responsable || '',
          product: subscriber.product || '',
          value: subscriber.value || '',
          extra1: subscriber.extra1 || '',
          extra2: subscriber.extra2 || '',
          last_purchase_date: subscriber.last_purchase_date || null,
          expiring_date: subscriber.expiring_date || null,
          notes: subscriber.notes || '',
          tags: subscriber.tags || []
        });
      } catch (error) {
        toast.error('Error fetching subscriber data');
      } finally {
        setLoading(false);
      }
    };

    const fetchResponsables = async () => {
      const data = await axiosInstance.get(`/api_get_operators?group_id=${group_id}`);
      const responsables = data.data.data;
      setResponsables(responsables);
    }

    fetchResponsables();
    fetchData();
  }, [id]);

  if (loading) {
    return <MDBox p={3}>Loading...</MDBox>;
  }

  return (
    <BaseLayout>
      <MDBox p={0}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" mb={3}>
              Edit Subscriber
            </MDTypography>

            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Full Name"
                    name="fullname"
                    value={formik.values.fullname}
                    onChange={formik.handleChange}
                    error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                    helperText={formik.touched.fullname && formik.errors.fullname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="First Name"
                    name="firstname"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={formik.touched.firstname && formik.errors.firstname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="datepicker">
                    <MDDatePicker
                      placeholder="Birthday"
                      value={formik.values.birthday}
                      onChange={(date) => formik.setFieldValue('birthday', date)}
                      error={formik.touched.birthday && Boolean(formik.errors.birthday)}
                      helperText={formik.touched.birthday && formik.errors.birthday}
                    />
                    <CalendarTodayIcon />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Company"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Number"
                    name="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={formik.touched.number && Boolean(formik.errors.number)}
                    helperText={formik.touched.number && formik.errors.number}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Complement"
                    name="complement"
                    value={formik.values.complement}
                    onChange={formik.handleChange}
                    error={formik.touched.complement && Boolean(formik.errors.complement)}
                    helperText={formik.touched.complement && formik.errors.complement}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="District"
                    name="district"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    error={formik.touched.district && Boolean(formik.errors.district)}
                    helperText={formik.touched.district && formik.errors.district}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="City"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="ZIP Code"
                    name="zip"
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                    helperText={formik.touched.zip && formik.errors.zip}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Legal ID"
                    name="legal_id"
                    value={formik.values.legal_id}
                    onChange={formik.handleChange}
                    error={formik.touched.legal_id && Boolean(formik.errors.legal_id)}
                    helperText={formik.touched.legal_id && formik.errors.legal_id}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={formik.touched.gender && Boolean(formik.errors.gender)}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      label="Gender"
                      sx={{ padding: '12px 5px;' }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && (
                      <FormHelperText>{formik.errors.gender}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
            

                <Grid item xs={12} md={6}>
                  {/* <MDInput
                    fullWidth
                    label="Responsable"
                    name="responsable"
                    value={formik.values.responsable}
                    onChange={formik.handleChange}
                    error={formik.touched.responsable && Boolean(formik.errors.responsable)}
                    helperText={formik.touched.responsable && formik.errors.responsable}
                  /> */}
                  <FormControl fullWidth error={formik.touched.gender && Boolean(formik.errors.gender)}>
                    <InputLabel>Responsable</InputLabel>
                    <Select
                      name="responsable"
                      value={formik.values.responsable}
                      onChange={formik.handleChange}
                      label="Responsable"
                      sx={{ padding: '12px 5px;' }}
                    >
                      {responsables && responsables.map((responsable) => (
                        <MenuItem key={responsable.id} value={responsable.opr_name}>
                          {responsable.opr_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.responsable && formik.errors.responsable && (
                      <FormHelperText>{formik.errors.responsable}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Product"
                    name="product"
                    value={formik.values.product}
                    onChange={formik.handleChange}
                    error={formik.touched.product && Boolean(formik.errors.product)}
                    helperText={formik.touched.product && formik.errors.product}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Value"
                    name="value"
                    type="number"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    error={formik.touched.value && Boolean(formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Extra 1"
                    name="extra1"
                    value={formik.values.extra1}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDInput
                    fullWidth
                    label="Extra 2"
                    name="extra2"
                    value={formik.values.extra2}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="datepicker">
                    <MDDatePicker
                      placeholder="Last Purchase Date"
                      value={formik.values.last_purchase_date}
                      onChange={(date) => formik.setFieldValue('last_purchase_date', date)}
                    />
                    <CalendarTodayIcon />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="datepicker">
                    <MDDatePicker
                      placeholder="Expiring Date"
                      value={formik.values.expiring_date}
                      onChange={(date) => formik.setFieldValue('expiring_date', date)}
                    />
                    <CalendarTodayIcon />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Notes"
                    name="notes"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl fullWidth error={formik.touched.tags && Boolean(formik.errors.tags)}>
                    <InputLabel>Tags</InputLabel>
                    <Select
                      multiple
                      name="tags"
                      value={formik.values.tags || []}
                      onChange={formik.handleChange}
                      label="Tags"
                      sx={{ padding: '12px 5px;', width: '100%' }}
                    >
                      {tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>
                          {tag.tag}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="flex-end" gap={2}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      onClick={() => navigate('/subscribers')}
                      sx={{ background: 'linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218))', color: '#000' }}
                    >
                      Back
                    </MDButton>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      sx={{ background: '#811fd1' }}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </BaseLayout>
  );
};

export default EditSubscriber;
