import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Autocomplete
  } from "@mui/material";
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'; // Ensure this path is correct
import youtubeIcon from 'assets/images/youtubeicons/youtube.jpg'; // Adjust the relative path
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import axiosInstance from 'api/axios';
import YtVideos from 'components/YtVideos';



const Report = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [ytData, setYtData] = useState([]);
  const [smsData, setSmsData] = useState([]);
    const group_id = localStorage.getItem('group_id');

    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSmsData = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_bot_sleSmsReport', {group_id: group_id});
            setSmsData(response.data.sent_count); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchYtData({page:'message_reports'});
        fetchSmsData()
    }, []);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleGenerateReport = () => {
    console.log("Generating report for:", { startDate, endDate });
  };

  const [open, setOpen] = useState(false);

  // Function to handle card click
  const handleCardClick = (title) => {
      setOpen(true);
  };

  // Function to close the popup
  const handleClose = () => {
    setOpen(false);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const data = [
    {
      date: "2024-12-10",
      name: "Campaign 1",
      message: "Message 1",
      image: "image1.jpg",
      eventDate: "2024-12-15",
      tags: "Tag1",
    },
    {
      date: "2024-12-11",
      name: "jhon",
      message: "Message 2",
      image: "image2.jpg",
      eventDate: "2024-12-16",
      tags: "Tag2",
    },
    // Add more data as needed
  ];

  //custome style table
  const cellStyles = {
    opacity: 0.7,
    background: 'transparent',
    color: 'rgb(123, 128, 154)',
    boxShadow: 'none',
    fontSize: '0.65rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center'
  };
  const tableCellStyle = {
    opacity: 1,
    background: 'transparent',
    color: 'rgb(123, 128, 154)',
    boxShadow: 'none',
    verticalAlign: 'middle',
    textAlign: 'center'
  };


  return (
    <Box sx={{ padding: 3, marginLeft: '15rem', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
        {/* Render the Navbar */}
        <DashboardNavbar />

        {
            ytData &&
            <Box className={'mb-4'}> 
                <YtVideos data={ytData} />
            </Box>
        }

        {/* Grid for Cards */}
        <Grid container spacing={0} sx={{marginTop:'0rem'}}>
            <Grid item xs={12}>
            


            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>

            <Card
                        
                        onClick={handleCardClick} // Open popup if "Ready to send" is clicked
                        sx={{
                        width: '300px',
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        textAlign: 'left',
                        padding: '40px',
                        backgroundColor: 'white',
                        cursor: 'pointer', // Add cursor pointer for clickable cards
                        '&:hover': { boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)' },
                        }}
                    >
                        <Typography
                        variant="h3"
                        sx={{
                            color: '#344767',
                            fontWeight: 'bold',
                            fontSize: '30px',
                            marginBottom: '4px',
                        }}
                        >
                        {smsData && smsData?.ready}
                        </Typography>
                        <Typography
                        variant="h6"
                        sx={{
                            color: 'rgb(52, 71, 103)',
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                        >
                        Ready to send
                        </Typography>
                    </Card>
            <Card
                        
                    // Open popup if "Ready to send" is clicked
                        sx={{
                        width: '300px',
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        textAlign: 'left',
                        padding: '40px',
                        backgroundColor: 'white',
                        cursor: 'pointer', // Add cursor pointer for clickable cards
                        '&:hover': { boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)' },
                        }}
                    >
                        <Typography
                        variant="h3"
                        sx={{
                            color: '#344767',
                            fontWeight: 'bold',
                            fontSize: '30px',
                            marginBottom: '4px',
                        }}
                        >
                        {smsData?.queue}
                        </Typography>
                        <Typography
                        variant="h6"
                        sx={{
                            color: 'rgb(52, 71, 103)',
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                        >
                        In queue for next days
                        </Typography>
                    </Card>
                    
               
            </Box>

            {/* Popup Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                {/* Close Icon */}
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>

                {/* Popup Content */}
                <DialogContent>
                <Typography
                    variant="h6"
                    sx={{
                    textAlign: 'center',
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#344767',
                    fontSize: '1.5rem'
                    }}
                >
                    Generate More Messages
                </Typography>

                {/* Buttons */}
                <DialogActions sx={{display:'flex',flexDirection:'column', gap: 2, justifyContent: 'center', paddingBottom: 3 }}>
                    <Button
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218))',
                            color: '#000000',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            textTransform: 'none',
                            padding: '10px 20px',
                        }}
                    >
                    GENERATE 30+ MESSAGE TO SEND NOW
                    </Button>


                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#F44336',
                            color: '#fff',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            textTransform: 'none',
                            padding: '10px 20px',
                            '&:hover': {
                            backgroundColor: '#d32f2f',
                            },
                        }}
                        onClick={handleClose}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
                </DialogContent>
            </Dialog>
            </Grid>
        </Grid>

        {/* Table */}
            
        <Box>
            <Paper elevation={1} sx={{ marginTop:3, padding:2 }}>
                <Box
                    sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 2,
                    }}
                >
                    <Autocomplete
                    options={[10, 20, 30, 40, 50]}
                    value={entriesPerPage}
                    onChange={(_, newValue) => setEntriesPerPage(newValue)}
                    disableClearable
                    renderInput={(params) => (
                        <TextField {...params} label="Entries per page" />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>
                        <Typography>{option}</Typography>
                        </li>
                    )}
                    />
                    <TextField
                    id="search"
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Box>

                <TableContainer sx={{boxShadow:'none'}}>
                    <Table>
                        <TableHead sx={{ display: 'table-header-group', padding: '1rem', borderRadius: '0.75rem 0.75rem 0 0' }}>
                            <TableRow>
                            <TableCell sx={cellStyles}>Date</TableCell>
                            <TableCell sx={cellStyles}>Name</TableCell>
                            <TableCell sx={cellStyles}>Message</TableCell>
                            <TableCell sx={cellStyles}>Image</TableCell>
                            <TableCell sx={cellStyles}>Event Date</TableCell>
                            <TableCell sx={cellStyles}>Tags</TableCell>
                            <TableCell sx={cellStyles}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                            .filter((item) =>
                                item.name.toLowerCase().includes(searchTerm.toLowerCase())
                            ) 
                            .slice(0, entriesPerPage)
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={tableCellStyle}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        {row.message}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        {row.image}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        {row.eventDate}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        {row.tags}
                                    </TableCell>
                                    <TableCell sx={tableCellStyle}>
                                        <IconButton
                                        sx={{ color: '#f50057' }}
                                        >
                                        <EditIcon />
                                        </IconButton>
                                        <IconButton
                                        sx={{ color: '#f50057', marginLeft: 1 }}
                                        >
                                        <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="body2" color="textSecondary">
                        Showing {entriesPerPage} of {data.length} entries
                    </Typography>
                </Box>
            </Paper>
        </Box>
    </Box>

  );
};

export default Report;