import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Icon,
  Autocomplete,
  Button,
  Modal,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import AddIcon from '@mui/icons-material/Add';
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import ImportExportIcon from '@mui/icons-material/ImportExport';
// import MDInput from "components/MDInput";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  handlePageChange,
  handlePageNext,
  handlePagePrev,
  paage,
  setPage
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const [search, setSearch] = useState(globalFilter);


  // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
  
    // Set the entries per page value based on the select value
    const setEntriesPerPage = (value) => setPageSize(value);
  
    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
      <MDPagination
        item
        key={option}
        onClick={handlePageChange}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  
    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) =>
      value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));
  
    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);
  
    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  
    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
      let sortedValue;
  
      if (isSorted && column.isSorted) {
        sortedValue = column.isSortedDesc ? "desc" : "asce";
      } else if (isSorted) {
        sortedValue = "none";
      } else {
        sortedValue = false;
      }
  
      return sortedValue;
    };
  
    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  
    // Setting the entries ending point
    let entriesEnd;
  
    if (pageIndex === 0) {
      entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
      entriesEnd = rows.length;
    } else {
      entriesEnd = pageSize * (pageIndex + 1);
    }

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);



 

  return (
    <>
      <TableContainer sx={{ boxShadow: "none" }}>
        {/* {entriesPerPage || canSearch ? (
          <CustomToolbar />
        ) : null} */}

        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <DataTableHeadCell
                    key={idx}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => (
                    <DataTableBodyCell
                      key={idx}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
              >
                {showTotalEntries && (
                  <MDBox mb={{ xs: 3, sm: 0 }}>
                    <MDTypography variant="button" color="secondary" fontWeight="regular">
                      Showing {entriesStart} to {entriesEnd} of {rows.length} entries
                    </MDTypography>
                  </MDBox>
                )}
                {/* {pageOptions.length > 1 && ( */}
                  <MDPagination
                    variant={pagination.variant ? pagination.variant : "gradient"}
                    color={pagination.color ? pagination.color : "info"}
                  >
                    {/* {canPreviousPage && ( */}
                      <MDPagination item onClick={handlePagePrev}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    {/* )} */}
                    <MDInput type="number" sx={{maxWidth: '70px'}} onChange={(e)=>handlePageChange(e.target.value)} value={paage}  />
                    {/* {renderPagination.length > 6 ? (
                      <MDBox width="5rem" mx={1}>
                        <MDInput
                          inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                          value={customizedPageOptions[pageIndex]}
                          onChange={(handleInputPagination, handleInputPaginationValue)}
                        />
                      </MDBox>
                    ) : (
                      renderPagination
                    )} */}
                    {/* {canNextPage && ( */}
                      <MDPagination item onClick={handlePageNext}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    {/* )} */}
                  </MDPagination>
                {/* )} */}
        </MDBox>
      </TableContainer>

      
    </>
  );
}

// PropTypes and defaultProps remain unchanged.

export default DataTable;

DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};