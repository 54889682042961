import { createTheme } from "@mui/material/styles";
import colors from "./colors.js"; // Make sure to replace this with the actual path to your colors file

const customTheme = createTheme({
  palette: {
    background: {
      default: colors.background.default, // General background color
      paper: colors.white.main, // Paper (card, dialog) background color
    },
    text: {
      primary: colors.text.main, // Primary text color
      secondary: colors.grey[500], // Secondary text color (can be customized)
    },
    primary: {
      main: colors.primary.main, // Primary color for buttons, links, etc.
      contrastText: colors.white.main, // Contrast color for text on primary color
    },
    secondary: {
      main: colors.secondary.main, // Secondary color
      contrastText: colors.white.main, // Contrast text color
    },
    success: {
      main: colors.success.main, // Success state color (green)
    },
    error: {
      main: colors.error.main, // Error state color (red)
    },
    info: {
      main: colors.info.main, // Info state color (light blue)
    },
    warning: {
      main: colors.warning.main, // Warning state color (orange)
    },
    grey: {
      100: colors.grey[100], // Light grey
      500: colors.grey[500], // Mid grey
      900: colors.grey[900], // Dark grey
    },
    text: {
      primary: colors.text.main, // Default text color
      secondary: colors.grey[600], // Secondary text color
    },
    action: {
      active: colors.primary.main, // Active state color for interactive elements
      hover: colors.primary.focus, // Hover state color for interactive elements
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // Default font family
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: colors.black.main,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      color: colors.black.main,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      color: colors.black.main,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      color: colors.text.main,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: colors.text.main,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary.main,
          color: colors.white.main,
          '&:hover': {
            backgroundColor: colors.primary.focus,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary.main, // Custom color for the AppBar
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white.main, // Card background color
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.text.main, // Default text color for typography
        },
      },
    },
    // Add more customizations for other components as needed
  },
});

export default customTheme;
