import React, {useState, useEffect} from 'react';

import BaseLayout from './components/BaseLayout';
import DataTable from "./components/DataTable";

import Card from "@mui/material/Card";
import { Box, Grid, Paper, Typography, Select, MenuItem, Button, TextField } from '@mui/material';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

import MDButton from 'components/MDButton';

import axios from 'axios';

import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import YtVideos from 'components/YtVideos';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { Edit, Delete } from '@mui/icons-material';
import { IconButton, Tooltip, Modal } from '@mui/material';
import axiosInstance from 'api/axios';

import {toast} from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
// import DataTableWithModal from 'components/DataTables/DataTableWithModal';

import MDInput from 'components/MDInput';



const Tags = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const group_id = localStorage.getItem('group_id');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tableData, setTableData] = useState(null); // Data for DataTable
    const [ytData, setYtData] = useState([]); // Data for DataTable
    const [tags, setTags] = useState([]); // Tags options
    const [selectedTag, setSelectedTag] = useState(''); // Selected tag
    const [selectedDate, setSelectedDate] = useState(null); // Selected date
    const [openModal, setOpenModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [newSubscriber, setNewSubscriber] = useState({
      name: "",
      email: "",
      mobile: "",
      company: "",
      tag_id: [],
    });



    const CustomToolbar = () => (
        <MDBox display="flex" justifyContent="space-between" className={'top-bar-flex gap-2'} alignItems="center" p={3}>
         
            <MDButton color="dark" sx={{background: '#480ca8'}} onClick={() => setOpenModal(true)}><AddIcon />
            Create Tag
            </MDButton>
        </MDBox>
      );

    useEffect(() => {
        fetchYtData({page:'your_public'});
    }, []);
    
    useEffect(() => {
        fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit });
    }, [offset, selectedTag, selectedDate])

    const handleEdit = (id) => {
        console.log("Edit clicked for:", id);
        navigate(`/subscribers/EditSubscriber/${id}`);
    };
      
    // Function to handle delete
    const handleDelete = (id, name) => {
        setSelectedUser({id, name});
        setDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.post(`/api_delete_user_group`, {user_id:selectedUser.id, group_id:group_id});
            toast.success('Subscriber deleted successfully');
            fetchData({group_id:group_id, tag:selectedTag, dob:selectedDate, offset: offset, limit: limit});
            setDeleteModal(false);
        } catch (error) {
            console.error('Error deleting subscriber:', error);
            toast.error('Error deleting subscriber');
        }
    };

    const fetchData = async (params = {}) => {
    try {
        const response = await axiosInstance.get(`/api_users_by_group_tags?group_id=${group_id}`); // Adjust API endpoint
        setTags(response.data.tags); 
        setTotalEntries(response.data.total);
        const rows = response.data.tags.map((row,i)=>{
            
            return (
                {
                    id : row.id,
                    tag : row.tag,
                    total_users : row.total_users,
                    created_at : row.created_at,
                    updated_at : row.updated_at,
                    actions: (
                        <>
                          <Tooltip title="View">
                            <IconButton sx={{ color: '#f72585' }} >
                              <ArrowForward />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row.user_id)} sx={{ color: '#f72585' }} >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(row.user_id, row.name)} sx={{ color: '#f72585' }}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </>
                      ),
                }
            )
        })
        const data = {
            columns: [
                { Header: "ID", accessor: "id", width: "20%" },
                { Header: "Tag", accessor: "tag", width: "25%" },
                { Header: "Total de Assinantes", accessor: "total_users" },
                { Header: "Criado", accessor: "created_at", width: "7%" },
                { Header: "Atualizado", accessor: "updated_at" },
                { Header: "actions", accessor: "actions", disableSorting: true }
            ],
            rows: rows
        }
        setTableData(data);
        console.log('data', data);
        
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    };
    const fetchYtData = async (params = {}) => {
    try {
        const response = await axiosInstance.get('/api_get_videos', { params });
        setYtData(response.data); 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    };

    const handlePageChange = (page) => {
        console.log(page, 'page');
        
    };
    const handlePagePrev = () => {
        if(page > 0){
            setPage(page - 1);
            setOffset(page * limit);
        }
    };
    const handlePageNext = () => {
        console.log('next', Math.ceil(totalEntries / limit), page);
        
        if(page < totalEntries / limit ){
            setPage(page + 1);
            setOffset(page * limit);
        }
    };
    


  return (
    <BaseLayout stickyNavbar={true}>
        
        <Card>
            <MDBox p={3} lineHeight={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <MDTypography variant="h5" fontWeight="medium">
                     Tags
                </MDTypography>
                <CustomToolbar />
            </MDBox>
            {
                tableData &&
                <DataTable table={tableData} canSearch handlePageChange={handlePageChange} handlePageNext={handlePageNext} handlePagePrev={handlePagePrev} paage={page} setPage={setPage} />
            }
        </Card>

   

 
    
    </BaseLayout>
  );
};

export default Tags;
