import { useState, useEffect } from 'react';
import axiosInstance from 'api/axios';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {Card, Box} from "@mui/material";

import YtVideos from 'components/YtVideos';

// Material Dashboard 3 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";

import { useNavigate } from 'react-router-dom';




function Analytics() {
  const { sales, tasks } = reportsLineChartData;

  const [ytData, setYtData] = useState([]); 

  const navigate = useNavigate();

  const fetchYtData = async (params = {}) => {
    try {
        const response = await axiosInstance.get('/api_get_videos', { params });
        setYtData(response.data); 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    };

    useEffect(() => {
      fetchYtData({page:'home'});
  }, []);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography
          variant="body1"
          color="info"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox pb={3}>
          {
              ytData &&
              <Box className={'mb-4'}> 
                  <YtVideos data={ytData} />
              </Box>
          }
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox>
                  <ComplexStatisticsCard
                    icon="weekend"
                    title="Bookings"
                    count={281}
                    
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Today's Users"
                    count="2,300"
                    
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox>
                  <ComplexStatisticsCard
                    icon="store"
                    title="Revenue"
                    count="34k"
                    // percentage={{
                    //   color: "success",
                    //   amount: "+1%",
                    //   label: "than yesterday",
                    // }}
                  />
                </MDBox>
              </Grid>
            
            </Grid>
          </MDBox>
          
        </MDBox>

        <MDBox pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Card className="auto-card">
                  <MDBox p={2}>
                    <div className="auto-card-header">
                      <MDTypography variant="h6">Automation Status</MDTypography>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => navigate('/new-qr/type/4')}
                        sx={{ background: '#1A73E8', margin : '0 auto', display: 'block',marginTop: '15px', marginBottom: '15px' }}
                      >
                        Read QRcode
                      </MDButton>
                    </div>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      
                      <MDTypography 
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", ml: 1 }}
                      >
                        Enable Automation
                      </MDTypography>
                      <Switch color="success" defaultChecked sx={{ '&.Mui-checked': { backgroundColor: '#811fd1' } }} />
                    </MDBox>
                  </MDBox>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Card className="auto-card" sx={{ height: '100%' }}>
                  <MDBox p={2}>
                    <div className="auto-card-header">
                      <MDTypography variant="h6">Automation and Multichannel Details</MDTypography>
                    </div>

                    <MDBox display="flex" gap={2} alignItems="center" mb={3} mt={3}>
                      <MDTypography 
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", ml: 0 }}
                      >
                       Server
                      </MDTypography>

                      <MDTypography 
                        variant="button"
                        fontWeight="medium"
                        color="text"
                        sx={{ cursor: "pointer", ml: 1, color: '#811fd1' }}
                      >
                          srv10.crmstation.net
                      </MDTypography>
                      
                    </MDBox>

                    <MDBox display="flex" flexGrow={1} gap={2} alignItems="center" mb={2} mt={2}>
                      <MDTypography 
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", ml: 0 }}
                      >
                       Automation Restart Timer
                      </MDTypography>

                      <MDBox display="flex"  flexGrow={1} alignItems="center">
                        <MDInput
                          type="number"
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            "& .MuiOutlinedInput-root": {
                              height: "35px"
                            }
                          }}
                        />
                        <MDButton
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ backgroundColor: '#811fd1' }}
                        >
                          Save
                        </MDButton>
                      </MDBox>
                      
                    </MDBox>
                  </MDBox>
                  
                </Card>
            </Grid>
          </Grid>
        </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
