import React, { useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Autocomplete,
    Switch
  } from "@mui/material";
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import youtubeIcon from 'assets/images/youtubeicons/youtube.jpg';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from 'api/axios';
import { useEffect } from 'react';
import YtVideos from 'components/YtVideos';
import {toast} from 'react-toastify';



const Campaigns_report = () => {
    const [openDialog, setOpenDialog] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [switchChecked, setSwitchChecked] = useState(false);
    const [ytData, setYtData] = useState([]);
    const [message_reports, setMessageReports] = useState([]);
    const [dailyLimit, setDailyLimit] = useState(0);

    const group_id = localStorage.getItem('group_id');

    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchMessageReports = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_compaign_sleSmsReport', { group_id: group_id });
            setMessageReports(response.data.sent_count); 
            setSwitchChecked(response.data.is_webcampaigns)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const generateMessage = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_updateReadySent_50', { group_id: group_id });
            fetchMessageReports()
            toast.success(`Updated Records ${response.data.updated_records}`);
            setOpenDialog(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error generating messages');
        }
    };

    useEffect(() => {
        fetchYtData({page:'message_reports'});
        fetchMessageReports()
    }, []);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleGenerateReport = () => {
        console.log("Generating report for:", { startDate, endDate });
    };

    const handleCardClick = (title) => {
       
        setOpenDialog("readyToSend");
        
    };
    const handleDailyCardClick = (title) => {
        
        
        setOpenDialog("dailyLimit");
        
    };

    const handleSaveDailyLimit = async () => {
        try {
            const response = await axiosInstance.post('/api_updateDailyLimit', { group_id: group_id, daily_limit: dailyLimit });
            fetchMessageReports()
            toast.success('Daily limit updated successfully');
            setOpenDialog(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error updating daily limit');
        }
    };

    // Function to close the dialog
    const handleClose = () => {
        setOpenDialog(null);
    };


    // Handle switch toggle
    const handleSwitchChange = async (event) => {
        const isChecked = event.target.checked;
        setSwitchChecked(isChecked);
        try {
            await axiosInstance.post('/api_status_webcampaign', { group_id: group_id, is_webcampaigns: isChecked });
            toast.success('Web campaign status updated successfully');
        } catch (error) {
            console.error('Error updating web campaign status:', error);
            toast.error('Error updating web campaign status');
        }
    };

    const data = [
        {
            date: "2024-12-10",
            name: "Campaign 1",
            message: "Message 1",
            image: "image1.jpg",
            eventDate: "2024-12-15",
            tags: "Tag1",
        },
        {
            date: "2024-12-11",
            name: "jhon",
            message: "Message 2",
            image: "image2.jpg",
            eventDate: "2024-12-16",
            tags: "Tag2",
        },
        // Add more data as needed
    ];

    //custome style table
    const cellStyles = {
        opacity: 0.7,
        background: 'transparent',
        color: 'rgb(123, 128, 154)',
        boxShadow: 'none',
        fontSize: '0.65rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center'
    };
    const tableCellStyle = {
        opacity: 1,
        background: 'transparent',
        color: 'rgb(123, 128, 154)',
        boxShadow: 'none',
        verticalAlign: 'middle',
        textAlign: 'center'
    };

    return (
        <Box sx={{ padding: 3, marginLeft: '15rem', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            {/* Render the Navbar */}
            <DashboardNavbar />

            {/* Grid for Cards */}
            <Grid container spacing={0} sx={{marginTop:'0rem'}}>
                <Grid item xs={12}>
                    {
                        ytData &&
                        <Box className={'mb-4'}> 
                            <YtVideos data={ytData} />
                        </Box>
                    }

                    <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 3 }}>
                        {/* Iterate through cards */}
                            <Card
                               
                                onClick={handleCardClick} // Open the appropriate dialog
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {message_reports?.ready}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                Ready to send
                                </Typography>
                            </Card>
                            <Card
                            
                               // Open the appropriate dialog
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {message_reports?.queue}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                In queue
                                </Typography>
                            </Card>
                            <Card
                       
                                onClick={handleDailyCardClick} // Open the appropriate dialog
                                sx={{
                                width: "645px",
                                borderRadius: 2,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                textAlign: "left",
                                padding: "40px",
                                backgroundColor: "white",
                                cursor: "pointer",
                                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)" },
                                }}
                            >
                                <Typography
                                variant="h3"
                                sx={{
                                    color: "#344767",
                                    fontWeight: "bold",
                                    fontSize: "30px",
                                    marginBottom: "4px",
                                }}
                                >
                                {message_reports?.daily_limit}
                                </Typography>
                                <Typography
                                variant="h6"
                                sx={{
                                    color: "rgb(52, 71, 103)",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                                >
                                Daily Limit
                                </Typography>
                            </Card>
                        

                        {/* Web Delivery Switch */}
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={12}>
                                <Switch
                                checked={switchChecked}
                                onChange={handleSwitchChange}
                                color="primary"
                                />
                                <Typography variant="h5">
                                {switchChecked ? "Web Delivery On" : "Web Delivery Off"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <Typography variant="body1" sx={{ fontSize: 11 }}>
                                Turn it on to send the messages using the Web Automation system.
                                For more than 100 deliveries per day, use our Windows App.
                                </Typography>
                            </Grid>
                            </Grid>
                        </Box>

                        {/* Dialog for "Ready to Send" */}
                        <Dialog
                            open={openDialog === "readyToSend"}
                            onClose={handleClose}
                            maxWidth="xs"
                            fullWidth
                        >
                            {/* Close Icon */}
                            <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                            </IconButton>

                            {/* Dialog Content */}
                            <DialogContent>
                            <Typography
                                variant="h6"
                                sx={{
                                textAlign: "center",
                                marginBottom: 2,
                                fontWeight: "bold",
                                color: "#344767",
                                fontSize: "1.5rem",
                                }}
                            >
                                Ready to send
                            </Typography>
                            <DialogActions
                                sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                justifyContent: "center",
                                paddingBottom: 3,
                                }}
                            >
                                <Button
                                variant="contained"
                                onClick={generateMessage}
                                sx={{
                                    background:
                                    "linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218))",
                                    color: "#000000",
                                    fontWeight: "bold",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    padding: "10px 20px",
                                }}
                                >
                                GENERATE 30+ MESSAGE TO SEND NOW
                                </Button>
                            </DialogActions>
                            </DialogContent>
                        </Dialog>

                        {/* Dialog for "Daily Limit" */}
                        {/* Dialog for "Daily Limit" */}
                        <Dialog
                            open={openDialog === "dailyLimit"}
                            onClose={handleClose}
                            maxWidth="xs"
                            fullWidth
                            sx={{
                                "& .MuiPaper-root": {
                                bottom: "30%",
                                },
                            }}
                        >
                            {/* Close Icon */}
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                position: "absolute",
                                right: 8,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            {/* Dialog Content */}
                            <DialogContent>
                                <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    marginBottom: 2,
                                    fontWeight: "bold",
                                    color: "#344767",
                                    fontSize: "1.5rem",
                                }}
                                >
                                Daily Limit
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        gap: 2,                
                                        width: "100%",          
                                    }}
                                    >
                                    <TextField
                                        id="outlined-number"
                                        label="Enter Limit"
                                        type="number"
                                        variant="outlined"
                                        value={dailyLimit}
                                        onChange={(e) => setDailyLimit(e.target.value)}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: undefined, 
                                        }}
                                        sx={{
                                            marginBottom: "8px",
                                            "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#6c43e0",
                                            },
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#6c43e0",
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                        width: "80px",
                                        backgroundColor: "#6c43e0",
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        borderRadius: "8px",
                                        textTransform: "none",
                                        "&:hover": { backgroundColor: "#5a38b8" },
                                        }}
                                        onClick={handleSaveDailyLimit}
                                    >
                                        SAVE
                                    </Button>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Grid>
            </Grid>

            {/* Table */}
                
            <Box>
                <Paper elevation={1} sx={{ marginTop:3, padding:2 }}>
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                        }}
                    >
                        <Autocomplete
                        options={[10, 20, 30, 40, 50]}
                        value={entriesPerPage}
                        onChange={(_, newValue) => setEntriesPerPage(newValue)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField {...params} />
                            
                        )}
                        sx={{
                            width: "auto",
                            "& .MuiOutlinedInput-root": {
                            padding: "3px 13px",
                            },
                        }}
                        renderOption={(props, option) => (
                            <li {...props}>
                            <Typography>{option}</Typography>
                            </li>
                        )}
                        />
                        <TextField
                        id="search"
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Box>

                    <TableContainer sx={{boxShadow:'none'}}>
                        <Table>
                            <TableHead sx={{ display: 'table-header-group', padding: '1rem', borderRadius: '0.75rem 0.75rem 0 0' }}>
                                <TableRow>
                                <TableCell sx={cellStyles}>Date</TableCell>
                                <TableCell sx={cellStyles}>Name</TableCell>
                                <TableCell sx={cellStyles}>Message</TableCell>
                                <TableCell sx={cellStyles}>Image</TableCell>
                                <TableCell sx={cellStyles}>Event Date</TableCell>
                                <TableCell sx={cellStyles}>Tags</TableCell>
                                <TableCell sx={cellStyles}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data
                                .filter((item) =>
                                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                                ) 
                                .slice(0, entriesPerPage)
                                .map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={tableCellStyle}>
                                            {row.date}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.message}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.image}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.eventDate}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.tags}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            <IconButton
                                            sx={{ color: '#f50057' }}
                                            >
                                            <EditIcon />
                                            </IconButton>
                                            <IconButton
                                            sx={{ color: '#f50057', marginLeft: 1 }}
                                            >
                                            <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                            Showing {entriesPerPage} of {data.length} entries
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default Campaigns_report;