import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Switch, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDDatePicker from 'components/MDDatePicker';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';  // Ensure this is correct path
import axiosInstance from 'api/axios'; // Import axios instance
import {toast} from 'react-toastify';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MDButton from 'components/MDButton';
import { format } from 'date-fns';

const Birthday = () => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [daysBefore, setDaysBefore] = useState('');
  const [message, setMessage] = useState('');
  const group_id = localStorage.getItem('group_id');
  const [anticipate, setAnticipate] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);


  const fetchData = async () => {
    try {
      const response = await axiosInstance.post('/api_bot_retreive', { group_id: group_id, table: 'bots_birthday' });
      console.log('API Response:', response.data);
      setAnticipate(response.data.anticipate);
      setMessage(response.data.body);
      setImage(response.data.image);
      setSwitchChecked(response.data.is_active);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateSave = async () => {
      try {
        const response = await axiosInstance.post('/api_bot_update_values', { group_id: group_id, anticipate: anticipate, table: 'bots_birthday' });
        // console.log('API Response:', response.data);
        toast.success('Data saved successfully');
      } catch (error) {
        // console.error('Error calling API:', error);
        toast.error('Error saving data');
      }
  };

  const handleDateChange = (newValue) => {
    const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : null;
    setSelectedDate(formattedDate);
  };

  const handleSwitchChange = async (event) => {
    const checked = event.target.checked;
    setSwitchChecked(checked);

    // Call API on switch change
    try {
      const response = await axiosInstance.post('/api_bot_update_active', { group_id: group_id, table: 'bots_birthday', is_active: checked });
      // console.log('API Response:', response.data);
      toast.success('Status changed successfully');
    } catch (error) {
      // console.error('Error calling API:', error);
      toast.error('Error changing status');
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleDaysBeforeChange = (event) => {
    setDaysBefore(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSave = async () => {
    console.log("Saved:", { switchChecked, anticipate, message, image });

    const formData = new FormData();
    formData.append('table', 'bots_birthday');
    formData.append('group_id', group_id);
    formData.append('body', message);
    formData.append('image', image);

    try {
      const response = await axiosInstance.post('/api_update_image_and_body', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('API Response:', response.data);
      toast.success('Data saved successfully');
    } catch (error) {
      console.error('Error calling API:', error);
      toast.error('Error saving data');
    }
  };

  const handleGenerateMessagesClick = () => {
    setOpen(true);
  };

  const handleGenerateMessages = async () => {
    try {
      const response = await axiosInstance.post('/api_generate_birthday_messages', { group_id: group_id, specificDay: selectedDate });
      console.log('API Response:', response.data);
      toast.success('Messages generated successfully');
      setOpen(false);
    } catch (error) {
      console.error('Error calling API:', error);
      toast.error('Error generating messages');
    }
  };

  return (
    <Box sx={{ padding: 3, marginLeft: '15rem', }}>
      {/* Render the Navbar */}
      <DashboardNavbar />  

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, paddingTop: 5, marginRight: 4 }}>
       <Button
            variant="contained"
            color="primary"
            sx={{
                background: 'linear-gradient(195deg, rgb(235, 239, 244), rgb(223 228 233)) !important',
                color: 'black !important',
            }}
            onClick={handleGenerateMessagesClick}
            >
            Generate Messages
        </Button>
      </Box>
  
      <Grid container spacing={3}>
        {/* Birthday Bot Section */}
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
              boxShadow: '0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)', // Corrected
            }}
          >
            <Box sx={{ padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>
                <Typography variant="h6" sx={{ color: 'rgb(52, 71, 103)' }}>Birthday Bot OFF</Typography>
              </Box>
              <Box>
                <Switch checked={switchChecked} onChange={handleSwitchChange} color="secondary" />
              </Box>
            </Box>
          </Paper>
        </Grid>
  
        <Grid item xs={12} md={8}>
          <Paper sx={{ height: '100%', borderRadius: 2, boxShadow: '0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)', }}>
            <Box sx={{ padding: 4, display: 'flex', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ color: 'rgb(52, 71, 103)' }}>
                How many days BEFORE the date you want to send the message
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: 2, width: 'auto' }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    sx={{ width: '5rem' }} 
                    value={anticipate}
                    onChange={(e) => setAnticipate(e.target.value)}
                  />
                </Box>
                <Box>
                  <Button variant="contained" color="primary" sx={{ background: '#8220D1 !important', color: '#fff' }} onClick={handleDateSave}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
  
        {/* Message Input and Image Upload */}
        <Grid item xs={12}>
          <Paper sx={{ height: '100%' }}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h5" sx={{ margin: '16px 0 8px', color: 'rgb(52, 71, 103)' }}>
                Type a message and choose an image
              </Typography>

              {/* Textarea and Image Upload in One Row */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    multiline
                    rows={15}
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    value={message}
                    onChange={handleMessageChange}
                  />
                </Grid>

                {/* Image Upload */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="image-upload"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#f0f0f0',
                      cursor: 'pointer',
                      height: '10rem',
                      border: '1px dashed gray',
                    }}
                  >
                    {
                      !image && (
                        <Typography variant="body1" sx={{ color: 'rgb(52, 71, 103)' }}>Click to upload image</Typography>
                      )
                    }
                    {
                      image && (
                        <img 
                          src={typeof image === 'string' ? image : URL.createObjectURL(image)} 
                          alt="Uploaded" 
                          style={{ width: '200px', height: '100%', objectFit: 'contain' }} 
                        />
                      )
                    }
                  </label>
                </Grid>
              </Grid>

              {/* Placeholders */}
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="body2" sx={{ color: 'rgb(52, 71, 103)' }}>
                  Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%,
                  %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%,
                  %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%,
                  %produto%, %valor%, %extra1%, %extra2%, %extra3%
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                <Button variant="contained" color="primary" sx={{ background: '#8220D1 !important', color: '#fff' }} onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal for Generate Messages */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Generate Messages</DialogTitle>
        <DialogContent>
            <div className="datepicker">
                <MDDatePicker placeholder="Select Date" value={selectedDate} onChange={handleDateChange} />
                <CalendarTodayIcon />
            </div>
          
        </DialogContent>
        <DialogActions>
          <MDButton 
            onClick={handleGenerateMessages} 
            sx={{ 
              background: '#8220D0', 
              color: '#fff',
              '&:hover': {
                color: '#fff',
                background: '#181818',
              },
            }}
          >
            Generate Messages
          </MDButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Birthday;
