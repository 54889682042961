import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material';

import yt from 'assets/images/yt.jpg';

const YtVideos = ({data}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={3}>
            {
                data.map((x, i) =>{
                    return(
                        <Grid item xs={6} md={6} lg={2} key={i}>
                            <Box>
                            <a
                                href={x.link} // Link from the data object
                                target="_blank" // Opens in a new tab
                                rel="noopener noreferrer" // Prevents security vulnerabilities
                                style={{ textDecoration: 'none' }} // Removes default anchor styles
                                >
                                <Paper
                                elevation={1}
                                sx={{
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    padding: '15px 5px'
                                }}
                                >
                                    <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
                                        <img
                                        src={yt}
                                        alt="About Video 2"
                                        style={{ width: '100%', borderRadius: '14px', transition: 'transform 0.3s ease', }}
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(-15px)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(0)')}
                                        />
                                    </Box>
                                    <Box className="text-center">
                                        <p style={{ fontSize: '12px', marginBottom: '0px', marginTop: '5px'}}>{x.title}</p>
                                    </Box>
                            
                                </Paper>
                            </a>
                            </Box>
                        </Grid>
                    )
                })
            }
        </Grid>
    </Box>
  )
}

export default YtVideos