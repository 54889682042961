
// Material Dashboard 3 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// import Reports from "layouts/messageBots/reports";
import YourPublic from "layouts/yourPublic";

import Report from "layouts/dashboards/analytics/components/Bot Message/Report";
import Birthday from "layouts/dashboards/analytics/components/Bot Message/Birthday";
import Welcome from "layouts/dashboards/analytics/components/Bot Message/Welcome";
import Appointment from "layouts/dashboards/analytics/components/Bot Message/Appointment"
import Purchase from "layouts/dashboards/analytics/components/Bot Message/Purchase";
import Expiring from "layouts/dashboards/analytics/components/Bot Message/Expiring";
import Campaigns_report from "layouts/dashboards/analytics/components/Campaigns/Campaigns_report";
import Campaigns_message from "layouts/dashboards/analytics/components/Campaigns/Campaigns_message";
import Generate_Campaigns from "layouts/dashboards/analytics/components/Campaigns/Generate_Campaigns";



import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Material Dashboard 3 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
 
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Home",
    key: "dashboards",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    noCollapse: true,
    component: <Analytics />,
   
  },
  {
    type: "collapse",
    name: "Your Public",
    key: "public",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/yourPublic",
    noCollapse: true,
    component: <YourPublic />,
  },
  {
    type: "collapse",
    name: "Leads and Vips",
    key: "leads",
    icon: <Icon fontSize="small">leaderboard</Icon>,
    route: "/dashboards/yourPublic",
    noCollapse: true,
    component: <Analytics />,
  },
  {
    type: "collapse",
    name: "Multichannel",
    key: "multichannel",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/dashboards/yourPublic",
    noCollapse: true,
    component: <Analytics />,
  },
  {
    type: "collapse",
    name: "Automize",
    key: "automize",
    icon: <Icon fontSize="small">manage</Icon>,
    collapse: [
      {
        name: "Flowbuilder",
        key: "flowbuilder",
        route: "/flowbuilder",
        component: <Analytics />,
      },
    
      {
        name: "Your Data",
        key: "your-data",
        route: "/your-data",
        component: <Analytics />,
      },
      {
        name: "AI Assistant",
        key: "ai-assistent",
        route: "/ai-assistant",
        component: <Analytics />,
      },
      {
        name: "Upload Image",
        key: "upload-image",
        route: "/upload-image",
        component: <Analytics />,
      },
    ]
  },
  // { type: "title", title: "Pages", key: "title-pages" },
  
 
  {
    type: "collapse",
    name: "Message Bots",
    key: "messageBots",
    icon: <Icon fontSize="small">message</Icon>,
    collapse: [
      {
        name: "Reports",
        key: "reports",
        route: "/reports",
        component: <Report />,
      },
      {
        name: "Birthday",
        key: "birthday",
        route: "/birthday",
        component: <Birthday />,
      },
      {
        name: "Welcome",
        key: "welcome",
        route: "/welcome",
        component: <Welcome />,
      },
      {
        name: "Appointment",
        key: "appointment",
        route: "/appointment",
        component: <Appointment />,
      },
      {
        name: "1st Purchase",
        key: "purchase",
        route: "/bot_firstpurchase",
        component: <Purchase />,
      },
      {
        name: "Expiring",
        key: "expiring",
        route: "/bot_expiring",
        component: <Expiring />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">campaign</Icon>,
    collapse: [
      {
        name: "Campaigns Reports",
        key: "campaignsR",
        route: "/campaigns-reports",
        component: <Campaigns_report />,
      },
      {
        name: "Compose Message",
        key: "composeMessage",
        route: "/compose-message",
        component: <Campaigns_message />,
      },
      {
        name: "Generate Campaign",
        key: "generateCampaign",
        route: "/generate-campaign",
        component: <Generate_Campaigns />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Integrations",
    key: "integration",
    icon: <Icon fontSize="small">settings</Icon>,
    collapse: [
      {
        name: "Our API",
        key: "ourApi",
        route: "/our-api",
        component: <Analytics />,
      },
      {
        name: "Hotmart",
        key: "hotmart",
        route: "/hotmart",
        component: <Analytics />,
      },
      {
        name: "Google Sheets",
        key: "googleSheets",
        route: "/google-sheets",
        component: <Analytics />,
      },
      {
        name: "Woo Commerce",
        key: "woo",
        route: "/woo-commerce",
        component: <Analytics />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Instagram Bot",
    key: "instagram",
    icon: <InstagramIcon />,
    route: "/instagram-bot",
    noCollapse: true,
    component: <Analytics />,
  },
  {
    type: "collapse",
    name: "Official Whatsapp API",
    key: "whatsapp",
    icon: <WhatsAppIcon />,
    route: "/official-whatsapp-api",
    noCollapse: true,
    component: <Analytics />,
  },
  {
    type: "collapse",
    name: "Metrics",
    key: "metric",
    icon: <Icon fontSize="small">equalizer</Icon>,
    route: "/metric",
    noCollapse: true,
    component: <Analytics />,
  },
 
];

export default routes;
