import React, { useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TablePagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Autocomplete,
    Switch
  } from "@mui/material";
  import { useTable, usePagination } from "react-table";
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import youtubeIcon from 'assets/images/youtubeicons/youtube.jpg';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import axiosInstance from 'api/axios';
import { useEffect } from 'react';
import YtVideos from 'components/YtVideos';
import {toast} from 'react-toastify';
import { useFormik } from 'formik';



const Campaigns_message = () => {
    const [openDialog, setOpenDialog] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [switchChecked, setSwitchChecked] = useState(false);
    const [campaignName, setCampaignName] = useState("");
    const [smsBody, setSmsBody] = useState("");
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [file, setFile] = useState(null);

    const [campaigns, setCampaigns] = useState(null);

    const group_id = localStorage.getItem('group_id');

    const [ytData, setYtData] = useState([]);

    const fetchYtData = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/api_get_videos', { params });
            setYtData(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchYtData({page:'message_reports'});
    }, []);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleGenerateReport = () => {
        console.log("Generating report for:", { startDate, endDate });
    };

    const handleCardClick = (title) => {
        if (title === "Ready to send") {
        setOpenDialog("readyToSend");
        } else if (title === "Daily Limit") {
        setOpenDialog("dailyLimit");
        }
    };

    // Function to close the dialog
    const handleClose = () => {
        setOpenDialog(null);
    };

    const handleCampaignNameChange = (event) => {
        setCampaignName(event.target.value);
    };

    const handleSmsBodyChange = (event) => {
        setSmsBody(event.target.value);
    };

    const handleSave = async () => {
        // Handle the save logic here
        if (file) {
            console.log("Uploaded File:", file.name);
        }

        const data = new FormData();
        data.append('group_id', group_id);
        data.append('campaign_name', campaignName);
        data.append('sms_body', smsBody);
        data.append('image', file);
        try {
            const response = await axiosInstance.post('/api_create_campaign', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Campaign saved successfully:', response.data);
            toast.success('Campaign created successfully');
        } catch (error) {
            console.error('Error saving campaign:', error);
            toast.error('Error saving campaign');
        }
    };

    // Handle switch toggle
    const handleSwitchChange = (event) => {
        setSwitchChecked(event.target.checked);
    };

    const fetchCampaigns = async (params = {}) => {
        try {
            const response = await axiosInstance.post('/api_compaign_template', { group_id: group_id });
            setCampaigns(response.data); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    //custome style table
    const cellStyles = {
        opacity: 0.7,
        background: 'transparent',
        color: 'rgb(123, 128, 154)',
        boxShadow: 'none',
        fontSize: '0.65rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center'
    };
    const tableCellStyle = {
        opacity: 1,
        background: 'transparent',
        color: 'rgb(123, 128, 154)',
        boxShadow: 'none',
        verticalAlign: 'middle',
        textAlign: 'center'
    };


    return (
        <Box sx={{ padding: 3, marginLeft: '15rem', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
            {/* Render the Navbar */}
            <DashboardNavbar />

            {/* Grid for Cards */}
            <Grid container spacing={0} sx={{marginTop:'5rem'}}>
                <Grid item xs={12}>
                    {
                        ytData &&
                        <Box className={'mb-4'}> 
                            <YtVideos data={ytData} />
                        </Box>
                    }
                   

                    <Box sx={{ padding: "16px", backgroundColor: "#fff" }}>
                        <Typography variant="h6" sx={{marginBottom: 3}}>Compose Message</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <TextField
                                label="Name the Message"
                                variant="outlined"
                                fullWidth
                                value={campaignName}
                                onChange={handleCampaignNameChange}
                            />
                            </Grid>

                            {/* Enter Text Input */}
                            <Grid item xs={12} md={6}>
                            <TextField
                                label="Enter text"
                                variant="outlined"
                                fullWidth
                                height
                                multiline
                                rows={8}
                                value={smsBody}
                                onChange={handleSmsBodyChange}
                                placeholder="Enter text"
                            />
                            </Grid>

                            {/* Logos and Banners File Upload */}
                            <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            >
                            <input
                                accept="image/*"
                                id="image-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <label
                                htmlFor="image-upload"
                                style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#f0f0f0',
                                cursor: 'pointer',
                                height: '11rem',
                                border: '1px dashed gray',
                                }}
                            >
                                {
                                    file ?
                                    <img src={file.type == 'string' ? file : URL.createObjectURL(file)} alt="Uploaded" style={{ width: '250px', height: '100%', objectFit: 'contain' }} />
                                    :
                                    <Typography variant="body1" sx={{ color: 'rgb(52, 71, 103)' }}>Click to upload image</Typography>
                                }
                            </label>
                            </Grid>

                            {/* Subscriber Customization */}
                            <Grid item xs={12}>
                            <Typography variant="h6">
                                Use %name% or %nome% to customize the Subscriber's name
                            </Typography>
                            </Grid>

                            {/* Save Button */}
                            <Grid item xs={12}>
                            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                sx={{ background: '#8220D1 !important', color: '#fff' }}
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                >
                                Save
                                </Button>
                            </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            {/* Table */}
                
            <Box>
                <Paper elevation={1} sx={{ marginTop:3, padding:2 }}>
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                        }}
                    >
                        <Autocomplete
                        options={[10, 20, 30, 40, 50]}
                        value={entriesPerPage}
                        onChange={(_, newValue) => setEntriesPerPage(newValue)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField {...params} />
                            
                        )}
                        sx={{
                            width: "auto",
                            "& .MuiOutlinedInput-root": {
                            padding: "3px 13px",
                            },
                        }}
                        renderOption={(props, option) => (
                            <li {...props}>
                            <Typography>{option}</Typography>
                            </li>
                        )}
                        />
                        <TextField
                        id="search"
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Box>

                    <TableContainer sx={{boxShadow:'none'}}>
                        <Table>
                            <TableHead sx={{ display: 'table-header-group', padding: '1rem', borderRadius: '0.75rem 0.75rem 0 0' }}>
                                <TableRow>
                                <TableCell sx={cellStyles}>ID</TableCell>
                                <TableCell sx={cellStyles}>Nome da Mensagem</TableCell>
                                <TableCell sx={cellStyles}>Criado em</TableCell>
                                <TableCell sx={cellStyles}>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaigns &&
                                campaigns?.data.filter((item) =>
                                    item.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
                                ) 
                                .slice(0, entriesPerPage)
                                .map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={tableCellStyle}>
                                            {row.id}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.campaign_name	}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            {row.created_at	}
                                        </TableCell>
                                        <TableCell sx={tableCellStyle}>
                                            <IconButton
                                            sx={{ color: '#f50057' }}
                                            >
                                            <EditIcon />
                                            </IconButton>
                                            <IconButton
                                            sx={{ color: '#f50057', marginLeft: 1 }}
                                            >
                                            <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                            Showing {entriesPerPage} of {campaigns && campaigns?.data.length} entries
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default Campaigns_message;