import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Alert,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import BaseLayout from "./components/BaseLayout";
import MDButton from "components/MDButton";
import axios from "axios";
import axiosInstance from "api/axios";

const QRCodeComponent = () => {
  const [alertOpen, setAlertOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const group_id = localStorage.getItem("group_id");
  const group_name = localStorage.getItem("group_name");

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleNewQRCode = async () => {
    setLoading(true);
    try {
      // Log off API call
      await axiosInstance.post("https://srv10.crmstation.net/wp/logoff", {instancia: `${group_id}_${group_name}`, remover_cache:false});
      
      let response;
      do {
        response = await axiosInstance.post("https://srv10.crmstation.net/wp/qrcode", {instancia: `${group_id}_${group_name}`, remover_cache:false});
      } while (!response.data.qrcode);
      
      setQrCodeSrc(response.data.qrcode); // Assuming the API returns a JSON with a field 'qrCodeUrl'
    } catch (error) {
      console.error("Error logging off or fetching new QR code:", error);
    } finally {
      setLoading(false);
    }

    // setLoading(true);
    // try {
    //   let response;
    //   do {
    //     response = await axiosInstance.post("https://srv10.crmstation.net/wp/qrcode", {instancia: `${group_id}_${group_name}`, remover_cache:false});
    //   } while (!response.data.qrcode);
    //   setQrCodeSrc(response.data.qrcode); 
    // } catch (error) {
    //   console.error("Error fetching new QR code:", error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleSendTestMessage = async () => {
    
    try {
      const response = await axiosInstance.post("https://srv10.crmstation.net/wp/sendMsg", {instancia: `${group_id}_${group_name}`, msg	:'This is a test message', number:'5521992983857@c.us'});
      if(!response.data.retorno){
        setErrorMsg('Parece que falhou. Confirme no Whatsapp do seu celular e se realmente não foi enviada, clique no botão NOVO QRCODE e leia novamente o qrcode.');
      }
    } catch (error) {
      console.error("Error sending test message:", error);
    }
  };

  const handleDisconnect = async () => {
 
    await axiosInstance.post("https://srv10.crmstation.net/wp/logoff", {instancia: `${group_id}_${group_name}`, remover_cache:false});

  }

  return (
    <BaseLayout>
      <Box className="">
        {/* Alert Section */}
        {alertOpen && (
          <Alert
            severity="error"
            sx={{
              color: "white !important",
              backgroundColor: "rgb(233, 30, 99)",
              display: "flex",
              fontSize: "14px !important",
              alignItems: "center",
            }}
            icon={<ErrorOutlineIcon />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            BEFORE you click at NEW QRCODE, open your WhatsApp app in your mobile
            phone, go to Settings, Connected Devices, and click at Connect New
            Device.
          </Alert>
        )}

        {/* Main Content Section */}
        <Paper elevation={0} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6" className="">
              Read QRCode at Server 4 (srv10.crmstation.net) (Wait for countdown
              to generate new QR code)
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <div id="message_container">
                {loading && (
                  <CircularProgress />
                )}
                {qrCodeSrc && (
                  <img
                    id="qr_id"
                    style={{
                      width: "150px",
                      height: "150px",
                      margin: "auto",
                      display: "block",
                    }}
                    src={`https://srv10.crmstation.net//${qrCodeSrc}`}
                    alt="QR Code"
                    />
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <MDButton
                  fullWidth
                  sx={{
                    color: "white !important",
                    backgroundColor: "rgb(25, 120, 2)",
                    marginBottom: "10px",
                    maxWidth: "200px",
                    '&:hover': {
                      backgroundColor: "rgb(25, 120, 2) !important",
                      color: "white",
                    },
                  }}
                  onClick={handleNewQRCode}
                >
                  New QRCode
                </MDButton>

                <MDButton
                  fullWidth
                  sx={{
                    color: "white !important",
                    backgroundColor: "rgb(247, 37, 135)",
                    marginBottom: "10px",
                    maxWidth: "200px",
                    '&:hover': {
                      backgroundColor: "rgb(247, 37, 135) !important",
                      color: "white",
                    },
                  }}
                  onClick={handleSendTestMessage}
                >
                  Send Test Message
                </MDButton>

                <MDButton
                  fullWidth
                  sx={{
                    color: "white !important",
                    backgroundColor: "rgb(129, 34, 208)",
                    maxWidth: "200px",
                    '&:hover': {
                      backgroundColor: "rgb(129, 34, 208) !important",
                      color: "white",
                    },
                  }}
                  onClick={handleDisconnect}
                >
                  Disconnect/Clean
                </MDButton>
              </Box>
            </Grid>
          </Grid>

          <div
            id="testResponse"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            {errorMsg}
          </div>
        </Paper>
      </Box>
    </BaseLayout>
  );
};

export default QRCodeComponent;
